import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontFamily: fonts.din2014Regular,
  },
  label: {
    marginBottom: '0.555rem',
    fontSize: '0.833rem',
    fontWeight: '400',
    color: '#61666C',
    lineHeight: '100%',
    '@media (max-width: 767px)': {
      fontSize: '3.333rem',
      marginBottom: '2.222rem',
    },
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  inputField: {
    width: '100%',
    padding: '0.555rem 0.833rem',
    fontSize: '0.973rem',
    fontWeight: '400',
    lineHeight: '142%',
    borderRadius: '4px',
    color: colors.primaryText,
    border: '1px solid rgba(29, 37, 45, 0.10)',
    transition: 'border-color 0.2s ease-in-out',
    outline: 'none',

    '@media (max-width: 767px)': {
      padding: '2.222rem 3.333rem',
      fontSize: '4.445rem',
      height: '10rem',
    },
  },
  withLeftIcon: {
    paddingLeft: '40px',
  },
  withRightIcon: {
    paddingRight: '40px',
  },
  iconLeft: {
    position: 'absolute',
    left: '12px',
    display: 'flex',
    alignItems: 'center',
    // pointerEvents: 'none',
    cursor: 'pointer',
    stroke: '#61666C',
    fill: 'none',
    ':not(#__unused__) > svg': {
      width: '1.388rem',
      height: '1.388rem',
    },
    '@media (max-width: 767px)': {
      ':not(#__unused__) > svg': {
        width: '5.555rem',
        height: '5.555rem',
      },
    },
  },
  iconRight: {
    position: 'absolute',
    right: '12px',
    display: 'flex',
    alignItems: 'center',
    ':not(#__unused__) > svg': {
      width: '1.388rem',
      height: '1.388rem',
    },
    // pointerEvents: 'none',
    cursor: 'pointer',
    stroke: '#61666C',
    fill: 'none',
    '@media (max-width: 767px)': {
      ':not(#__unused__) > svg': {
        width: '5.555rem',
        height: '5.555rem',
      },
    },
  },
  error: {
    marginTop: '8px',
    fontSize: '0.875rem',
    color: '#d9534f',
  },
});
