import { SelectionHeader } from '@/features/header/ui/selection-header/ui';
import { IApartment } from '@/pages/roompicker-page/model/types';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SelectionContextType } from '@/pages/selection/model/types.ts';
import { generatePickQuery, generateStatsQuery } from '@/app/api/graphql-query.ts';
import { parsePick } from '@/app/api/graphql-pick.ts';
import {
  emptyFilters,
  emptyHistograms,
  FilterKey,
  RoompickerFiltersValues,
  RoompickerHistogramValues,
  SortKey,
} from '@/app/api/common-types.ts';
import { parseStats } from '@/app/api/graphql-stats.ts';
import { updateFilterStats } from '@/app/api/graphql-common.ts';

export const SelectionLayout = () => {
  const [availableFilters, setAvailableFilters] = useState(emptyFilters);
  const [histograms, setHistograms] = useState(emptyHistograms);
  const [selectedFilters, setSelectedFilters] = useState(emptyFilters);
  const [currentRanges, setCurrentRanges] = useState(emptyHistograms);
  const [sortOption, setSortOption] = useState(SortKey.PriceDesc);
  const [apartsToShow, setApartsToShow] = useState<IApartment[]>([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const filterAvailableFilters = (newAvailableFilters: RoompickerFiltersValues) => {
    const filtered = emptyFilters();
    Object.values(FilterKey).forEach((k) => {
      if (FilterKey[k as FilterKey] != undefined) {
        const key = k as FilterKey;
        const update = updateFilterStats(key, availableFilters[key], newAvailableFilters[key]);
        if (update) {
          filtered[key] = update;
        }
      }
    });
    return filtered;
  };

  const backendUrl =
    window.location.hostname === 'localhost' ? 'http://localhost:3030/' : 'https://graphgate-dev.sminex.site/';

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prev) => !prev);
  };

  useEffect(() => {
    const fetchPick = async () => {
      const query = { query: generatePickQuery(selectedFilters, sortOption) };
      const response = await fetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(query),
      });
      const data = await response.text();
      const apartments = parsePick(data);
      setApartsToShow(apartments);
    };
    fetchPick().catch((error) => {
      console.error(error);
    });
  }, [selectedFilters, sortOption]);

  useEffect(() => {
    const fetchStats = async () => {
      const query = { query: generateStatsQuery(selectedFilters) };
      const response = await fetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(query),
      });
      const data = await response.text();
      const [filters, histograms] = parseStats(data);
      setAvailableFilters(filterAvailableFilters(filters));
      setHistograms(histograms);
    };
    fetchStats().catch((error) => {
      console.error(error);
    });
  }, [selectedFilters]);

  const context = (): SelectionContextType => {
    return {
      isFilterVisible,
      toggleFilterVisibility,
      availableFilters,
      setAvailableFilters: (filters: RoompickerFiltersValues) => {
        setAvailableFilters(filters);
      },
      histograms,
      setHistograms: (histograms: RoompickerHistogramValues) => {
        setHistograms(histograms);
      },
      selectedFilters,
      setSelectedFilters: (filters: RoompickerFiltersValues) => {
        console.log(filters);
        setSelectedFilters(filters);
      },
      currentRanges,
      setCurrentRanges: (ranges: RoompickerHistogramValues) => {
        console.log('set ranges: ' + ranges);
        setCurrentRanges(ranges);
      },
      sortOption,
      setSortOption: (option: SortKey) => {
        console.log('set sort option: ' + option);
        setSortOption(option);
      },
      apartsToShow,
      setApartsToShow: (el: IApartment[]) => {
        setApartsToShow(el);
      },
    };
  };

  return (
    <>
      <SelectionHeader
        isFilterVisible={isFilterVisible}
        toggleFilterVisibility={toggleFilterVisibility}
        //setSelectedFilters={setSelectedFilters}
        apartsToShow={apartsToShow}
      />
      <Outlet context={context()} />
    </>
  );
};
