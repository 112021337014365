import { useEffect, useState } from 'react';
import CheckBox from '@/enteties/checkbox/ui';
import { enrichFilterItems } from '@/enteties/checkbox-group/lib';
import { ICheckboxFilterItem, ICheckBoxProps, IFilterItem } from '@/enteties/checkbox-group/model/types.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/enteties/checkbox-group/ui/styles.ts';

interface CheckBoxGroupProps extends ICheckBoxProps {
  onFilterChange?: (filterValue: string) => void; // добавляем onFilterChange в пропсы
}

const updateAvailableFilters = (prev: ICheckboxFilterItem[], next: IFilterItem[]) => {
  return next.map((item) => {
    const prevItem = prev.find((prevItem) => prevItem.id === item.id);
    const checked = prevItem?.checked || false;
    const checkItem: ICheckboxFilterItem = {
      ...item,
      checked
    };
    return checkItem;
  });
};

const CheckBoxGroup = ({ title, filters, onCheckboxClick, onFilterChange }: CheckBoxGroupProps) => {
  const [filtersState, setFiltersState] = useState<ICheckboxFilterItem[]>(enrichFilterItems(filters));

  useEffect(() => {
    setFiltersState((prev) => updateAvailableFilters(prev, filters));
  }, [filters]);

  //if (!title && (!filters || filters.length > 0)) return null;

  return (
    <div {...stylex.props([styles.container])}>
      {Boolean(title) && <div {...stylex.props([styles.checkboxGroupTitle])}>{title}</div>}
      <div {...stylex.props([styles.checkboxGroupContainer])}>
        {filtersState?.map((item) => (
          <CheckBox
            key={item.id}
            title={item.title}
            isChecked={item.checked}
            onChange={() => {
              setFiltersState((prev) => prev.map((el) => (el.id === item.id ? { ...el, checked: !el.checked } : el)));
              onFilterChange?.(item.title);
              onCheckboxClick?.(item.id);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckBoxGroup;
