import { useEffect, useState } from 'react';
import { SminexLogo } from '@/shared/ui/icons/Sminex';
import styles from './selection-header.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HeaderMenuBtn } from '../../header-menu-btn';
import { HeaderOptionsBtn } from '../../header-options-btn';
import { PhoneNumber } from '../../phone-number';
import { Menu } from '../../menu';
import { DEFAULT_HEADER_TEXT, headerTexts } from '@/features/header/model/headerPathText';
import { TabMenu } from '../../tab-menu';
import { routes } from '@/shared/constants/routes';
import { Flag } from '@/shared/ui/icons/flag';
import { PHONE_NUMBER } from '@/shared/lib/consts';
import { BackBtnArrow } from './BackBtnArrow';
import { IApartment } from '@/pages/roompicker-page/model/types';
//import { RoompickerFiltersValues } from '@/app/api/graphql-stats.ts';

export interface SelectionHeaderProps {
  isFilterVisible: boolean;
  toggleFilterVisibility: () => void;
  //setSelectedFilters: (filters: RoompickerFiltersValues) => void;
  apartsToShow: IApartment[];
}

export const SelectionHeader = (
  {
    isFilterVisible,
    toggleFilterVisibility,
    //setSelectedFilters,
    apartsToShow,
  }: SelectionHeaderProps) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const basePath = pathname.replace(`/${id}`, '');
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = isMenuVisible ? 'hidden' : 'auto';
  }, [isMenuVisible]);

  const headerDescText: string = headerTexts[basePath] || DEFAULT_HEADER_TEXT;

  const handleMenuClose = () => {
    setIsMenuVisible(false);
  };
  const isSelection = pathname.includes('/selection');

  return (
    <>
      <div className={`${styles.headerContainer} ${isMenuVisible ? styles.clicked : ''}`}>
        {isFilterVisible ? (
          <div className={styles.filterVisibleHeader}>
            <div className={styles.backButton} role="button" onClick={toggleFilterVisibility}>
              <div className={styles.selectionBackButtonIcon}>
                <BackBtnArrow />
              </div>
              Назад
            </div>
            <h2 className={styles.filterHeader}>Фильтр</h2>
          </div>
        ) : (
          <>
            <div className={styles.menuWithLogoCont}>
              <div className={`${styles.menuWithLogoWrap} ${isMenuVisible ? styles.clicked : ''}`}>
                <HeaderMenuBtn onClickFunc={() => setIsMenuVisible(!isMenuVisible)} isMenuVisible={isMenuVisible} />
              </div>
              <div
                className={`${styles.sminexLogoWrap} ${isMenuVisible ? styles.clicked : ''}`}
                onClick={() => navigate('/')}
              >
                <SminexLogo />
              </div>
            </div>
            {!isMenuVisible && (
              <div onClick={() => navigate(routes.public.allNewsPage)} role="button" className={styles.headerDesc}>
                Информационный центр
              </div>
            )}
            <div className={styles.optionsAndNumberBlock}>
              <div className={`${styles.headerOptionsWrap} ${isMenuVisible ? styles.clicked : ''}`}>
                Выбрать недвижимость
                <HeaderOptionsBtn />
              </div>
              <div onClick={() => navigate('')} role="button" className={styles.selectionFlag}>
                <Flag />
              </div>
              <PhoneNumber phone={PHONE_NUMBER} isMenuVisible={isMenuVisible} headerType="selection" />
            </div>
          </>
        )}
      </div>

      <div className={`${styles.menuWrapper} ${isMenuVisible ? styles.visible : ''}`}>
        <Menu onClose={handleMenuClose} isSelection={isSelection} />
      </div>
      <TabMenu
        apartsToShow={apartsToShow}
        isFilterVisible={isFilterVisible}
        toggleFilterVisibility={toggleFilterVisibility}
        setIsMenuVisible={setIsMenuVisible}
        isMenuVisible={isMenuVisible}
        //setSelectedFilters={setSelectedFilters}
      />
    </>
  );
};
