import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  selectionContainer: {
    display: 'flex',
    padding: '5.833rem 4.166rem',
    paddingBottom: '1.388rem',
    background: colors.brandGhostBackground,
    '@media (max-width: 767px)': {
      padding: '4.444rem',
      paddingBottom: '26.667rem',
    },
  },
  filterWrap: (filterVisible: boolean) => ({
    // maxWidth: '21.667rem',
    width: '28.373rem',

    boxSizing: 'border-box',
    '@media (max-width: 767px)': {
      display: filterVisible ? '' : 'none',
      maxWidth: '100%',
      marginTop: filterVisible ? '11.06rem' : '27rem',
      width: 'unset',
    },
  }),
  checkBoxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginTop: '0.833rem',
    backgroundColor: colors.primaryBackground,
    padding: '12px',
    borderRadius: '12px',
    gap: '24px',
    '@media (max-width: 767px)': {
      marginTop: '3.333rem',
    },
  },
  dopdownWrap: {
    background: '#fff',
    padding: '0.833rem',
    borderRadius: '0.833rem',
    '@media (max-width: 767px)': {
      padding: '3.333rem',
      borderRadius: '3.333rem',
    },
  },
  toggleSwitcherWrap: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.111rem',
  },
  inputWrap: {},
});
