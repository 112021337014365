import { ADVANTAGES_FIELDS, getAllProfiles, getTypeProfiles, mapLevels } from '@/app/api/graphql-common.ts';
import { FilterKey, RoompickerFiltersValues, SortKey } from '@/app/api/common-types.ts';

const LOTS_PER_PAGE = 20;
const HISTOGRAM_RESOLUTION = 20;

export function generateStatsQuery(filters: RoompickerFiltersValues) {
  const filter = generateFilter(filters, false);

  const advantages = ADVANTAGES_FIELDS.map((v) => `{entity:"flat",field:"${v}",kind:"values"}`);

  return (
    `query{stats(${filter}stats:{fields:[` +
    '{entity:"project",field:"Наименование",kind:"values"}' +
    '{entity:"building",field:"Наименование",kind:"values"}' +
    '{entity:"flat",field:"Профиль",kind:"values"}' +
    '{entity:"flat",field:"КоличествоКомнат",kind:"values"}' +
    '{entity:"flat",field:"Типоразмер",kind:"values"}' +
    '{entity:"flat",field:"КоличествоУровней",kind:"values"}' +
    '{entity:"flat",field:"НомерСекции",kind:"values"}' +
    `${advantages}` +
    '{entity:"flat",field:"ТипКухни",kind:"values"}' +
    '{entity:"flat",field:"ВыходОкон",kind:"values"}' +
    '{entity:"flat",field:"СтороныСвета",kind:"values"}' +
    `{entity:"flat",field:"ОсновнойЭтаж",kind:"histogram",histogramResolution:${HISTOGRAM_RESOLUTION}}` +
    `{entity:"flat",field:"Площадь",kind:"histogram",histogramResolution:${HISTOGRAM_RESOLUTION}}` +
    `{entity:"flat",field:"ЦенаЭкспонированияСоСкидкой",kind:"histogram",histogramResolution:${HISTOGRAM_RESOLUTION}}` +
    `{entity:"flat",field:"ЦенаЭкспонированияСоСкидкойUSD",kind:"histogram",histogramResolution:${HISTOGRAM_RESOLUTION}}` +
    `{entity:"flat",field:"ЦенаЭкспонированияСоСкидкойEUR",kind:"histogram",histogramResolution:${HISTOGRAM_RESOLUTION}}` +
    `{entity:"flat",field:"ЦенаЭкспонированияСоСкидкойCNY",kind:"histogram",histogramResolution:${HISTOGRAM_RESOLUTION}}` +
    `{entity:"floor",field:"ВысотаПотолка",kind:"histogram",histogramResolution:${HISTOGRAM_RESOLUTION}}` +
    ']}){fields{entity,field,values,histogram{min,max,data}}}}'
  );
}

export function generatePickQuery(filters: RoompickerFiltersValues, sort: SortKey) {
  const filter = generateFilter(filters, false);
  let order = '';
  switch (sort) {
    case SortKey.PriceDesc:
      order = '{entity:"flat",field:"ЦенаЭкспонированияСоСкидкой",desc:true}';
      break;
    case SortKey.PriceAsc:
      order = '{entity:"flat",field:"ЦенаЭкспонированияСоСкидкой",desc:false}';
      break;
    case SortKey.AreaDesc:
      order = '{entity:"flat",field:"Площадь",desc:true}';
      break;
    case SortKey.AreaAsc:
      order = '{entity:"flat",field:"Площадь",desc:false}';
      break;
    case SortKey.BedroomsDesc:
      order = '{entity:"flat",field:"КоличествоКомнат",desc:true}';
      break;
    case SortKey.BedroomsAsc:
      order = '{entity:"flat",field:"КоличествоКомнат",desc:false}';
      break;
  }

  const flatFields = [
    'Наименование',
    'Профиль',
    'КоличествоКомнат',
    'Типоразмер',
    'ОсновнойЭтаж',
    'Площадь',
    'Цена*',
    'СостояниеПродажи',
    'КоличествоУровней',
    'НомерСекции',
    'ТипКухни',
    'ВыходОкон',
    'СтороныСвета',
  ];
  filters[FilterKey.Advantages].forEach((v) => flatFields.push(v.replaceAll(' ', '')));
  const flatFieldsStr = flatFields.map((v) => `"${v}"`).join(',');

  return `query{pick(${filter}order:{fields:[${order}]}pagination:{first:${LOTS_PER_PAGE}}){quantity,connection{nodes{project{attributes(fields:["Наименование","Стадия"]){name,value}},building{attributes(fields:["Наименование"]){name,value}},flat{flatId,attributes(fields:[${flatFieldsStr}]){name,value},planes{link,level,bedrooms,planOrder,planType}}}}}}`;
}

function generateFilter(filters: RoompickerFiltersValues, disableProjects: boolean) {
  let profilesValues =
    filters[FilterKey.Profile].length > 0
      ? filters[FilterKey.Profile]
      : filters[FilterKey.Type].map((type) => getTypeProfiles(type)).flat(1);
  if (profilesValues.length === 0) {
    profilesValues = getAllProfiles();
  }
  const types = `{field:"Профиль",oneOf:[${profilesValues.map((v) => `"${v}"`).join(',')}]}`;

  const projectsValues = filters[FilterKey.Project].map((value) => `"${value}"`).join(',');
  const projects = projectsValues && !disableProjects ? `{field:"Наименование",oneOf:[${projectsValues}]}` : '';

  const buildingsValues = filters[FilterKey.Building].map((value) => `"${value}"`).join(',');
  const buildings = buildingsValues ? `{field:"Наименование",oneOf:[${buildingsValues}]}` : '';

  const sectionsValues = filters[FilterKey.Section].map((value) => `"${value}"`).join(',');
  const sections = sectionsValues ? `{field:"НомерСекции",oneOf:[${sectionsValues}]}` : '';

  const bedroomsValues = filters[FilterKey.Bedrooms].map((value) => `"${value}"`).join(',');
  const bedrooms = bedroomsValues ? `{field:"КоличествоКомнат",oneOf:[${bedroomsValues}]}` : '';

  const sizesValues = filters[FilterKey.Sizes].map((value) => `"${value}"`).join(',');
  const sizes = sizesValues ? `{field:"Типоразмер",oneOf:[${sizesValues}]}` : '';

  const levelValues = mapLevels(filters[FilterKey.Levels])
    .map((value) => `"${value}"`)
    .join(',');
  const levels = levelValues ? `{field:"КоличествоУровней",oneOf:[${levelValues}]}` : '';

  const booked = `{field:"СостояниеПродажи",oneOf:[${filters[FilterKey.HideBooked].length === 0 ? '"Забронировано",' : ''}"Экспонирование"]}`;
  const completed = filters[FilterKey.ProjectIsComplete].length === 0 ? '' : '{field:"Стадия",oneOf:["Построен"]}';

  const flatNumValue = filters[FilterKey.FlatNumber] ? filters[FilterKey.FlatNumber][0] : undefined;
  const flatNum = flatNumValue ? `{field:"Наименование",oneOf:["${flatNumValue}"],exactMatch:true}` : '';

  const advantages = filters[FilterKey.Advantages]
    .map((v) => `{field:"${v.replaceAll(' ', '')}",oneOf:["true"]}`)
    .join('');

  const kitchenTypeValues = filters[FilterKey.KitchenType].map((value) => `"${value}"`).join(',');
  const kitchenTypes = kitchenTypeValues ? `{field:"ТипКухни",oneOf:[${kitchenTypeValues}]}` : '';

  const planesValues = filters[FilterKey.Plan].map((value) => `"${value}"`).join(',');
  const planes = planesValues ? `{field:"ВыходОкон",oneOf:[${planesValues}]}` : '';

  console.log(filters[FilterKey.Orientation]);
  const orientationsValues = filters[FilterKey.Orientation].map((value) => `"${value}"`).join(',');
  const orientations = orientationsValues ? `{field:"СтороныСвета",oneOf:[${orientationsValues}],wordMatch:true}` : '';

  return `filter:{project:[${projects}${completed}],building:[${buildings}],floor:[],flat:[${types}${bedrooms}${sizes}${booked}${flatNum}${levels}${sections}${advantages}${kitchenTypes}${planes}${orientations}]}`;
}
