import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { Dropdown } from '@/shared/ui/components/dropdown';
import { CheckBoxGroup } from '@/enteties/checkbox-group';
import { useCallback, useState } from 'react';
import { getSortLogic } from '@/features/roompicker-filter-panel/lib/sort';
import { Input } from '@/shared/ui/components/input';
import { Glass } from '@/shared/ui/icons/glass';
import { CurrencySwitcher } from '@/features/currency-switcher/ui';
import { ToggleSwitch } from '@/shared/ui/components/toggle-switcher/ui';
import { InputRange } from '@/features/input-range';
import { RoompickerPage } from '@/pages/roompicker-page';
import { useOutletContext } from 'react-router-dom';
import { SelectionContextType } from '../model/types';
import { IFilterItem } from '@/enteties/checkbox-group/model/types.ts';
import { ISortOption } from '@/shared/model/types.ts';
import { FilterKey, HistogramKey } from '@/app/api/common-types.ts';
import { LOT_CARDS_CONSTS } from '@/shared/lib/consts.ts';

const INDEX_OF_ABSENT_ELEMENT = -1;
export const Selection = () => {
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  //const [histogramStats, _setHistogramStats] = useState(parsedHistogramStats);
  const [inputApartmentNumber, setInputApartmentNumber] = useState('');
  const {
    isFilterVisible,
    toggleFilterVisibility,
    availableFilters,
    histograms,
    selectedFilters,
    setSelectedFilters,
    currentRanges,
    setCurrentRanges,
    setSortOption,
  } = useOutletContext<SelectionContextType>();

  const onProjectClickHandler = useCallback((id: string | null) => {
    setSelectedProjects((prev) => {
      if (!id) return prev;
      const indexOfId = prev.indexOf(id);
      const projects =
        indexOfId === INDEX_OF_ABSENT_ELEMENT
          ? [...prev, id]
          : [...prev.slice(0, indexOfId), ...prev.slice(indexOfId + 1)];
      if (projects.length === 0) {
        const filtersWithoutBuilding = {
          ...selectedFilters,
          [FilterKey.Building]: [],
        };
        setSelectedFilters(filtersWithoutBuilding);
      }
      return projects;
    });
  }, []);

  const handleSearch = () => {
    const next = {
      ...selectedFilters,
      [FilterKey.FlatNumber]: inputApartmentNumber ? [inputApartmentNumber.trim()] : [],
    };
    setSelectedFilters(next);
  };

  const handleFilterChange = (category: FilterKey) => (filterValue: string) => {
    const prev = selectedFilters;
    const next = {
      ...prev,
      [category]: prev[category]?.find((v) => v == filterValue)
        ? prev[category].filter((v) => v !== filterValue)
        : [...(prev[category] || []), filterValue],
    };
    setSelectedFilters(next);
  };

  const handleOptionChange = (category: FilterKey) => (optionValue: ISortOption) => {
    const next = {
      ...selectedFilters,
      [category]: [optionValue.id],
    };
    setSelectedFilters(next);
  };

  const handleRangeChange = (category: HistogramKey, min: number, max: number) => {
    const next = {
      ...currentRanges,
      [category]: [String(min), String(max)], // Преобразуем числа в строки для унификации
    };
    setCurrentRanges(next);
  };

  const handleSwitch = (category: FilterKey) => (checked: boolean) => {
    const next = {
      ...selectedFilters,
      [category]: checked ? ['true'] : [],
    };
    setSelectedFilters(next);
  };

  const sortProjects = (values: string[]): string[] => {
    const sortLogic = getSortLogic(selectedProjects);
    return [...values].sort((a, b) => sortLogic.indexOf(a) - sortLogic.indexOf(b));
  };

  const convertFilter = (values: string[]): IFilterItem[] => {
    return values.map((value) => {
      const item: IFilterItem = { id: value, title: value };
      return item;
    });
  };

  const convertOptions = (values: string[]): ISortOption[] => {
    return values.map((value) => {
      const options: ISortOption = { id: value, title: value };
      return options;
    });
  };

  return (
    <>
      <div {...stylex.props(styles.selectionContainer)}>
        <div {...stylex.props(styles.filterWrap(isFilterVisible))}>
          <div {...stylex.props(styles.dopdownWrap)}>
            <Dropdown
              options={convertOptions(availableFilters[FilterKey.Type])}
              isSelectionPropertyTypeKey={true}
              label="Тип недвижимости"
              onSelect={handleOptionChange(FilterKey.Type)}
              defaultOptionId={undefined}
            />
          </div>
          <div {...stylex.props(styles.checkBoxWrapper)}>
            <Input
              rightIcon={<Glass />}
              placeholder="Введите номер квартиры"
              label="Номер лота"
              onChange={(e) => setInputApartmentNumber(e.target.value)}
              onClick={handleSearch}
            />
            <CurrencySwitcher />
            <CheckBoxGroup
              title="Проект"
              filters={convertFilter(sortProjects(availableFilters[FilterKey.Project]))}
              onCheckboxClick={onProjectClickHandler}
              onFilterChange={handleFilterChange(FilterKey.Project)}
            />
            <div {...stylex.props(styles.toggleSwitcherWrap)}>
              <ToggleSwitch label="Дом готов" onChange={handleSwitch(FilterKey.ProjectIsComplete)} />
              <ToggleSwitch label="Скрыть забронированные" onChange={handleSwitch(FilterKey.HideBooked)} />
            </div>

            <CheckBoxGroup
              title={'Количество спален'}
              filters={convertFilter(availableFilters[FilterKey.Bedrooms])}
              onFilterChange={handleFilterChange(FilterKey.Bedrooms)}
            />
            <CheckBoxGroup
              title={'Размер спален'}
              filters={convertFilter(availableFilters[FilterKey.Sizes])}
              onFilterChange={handleFilterChange(FilterKey.Sizes)}
            />
            <CheckBoxGroup
              title={'Тип'}
              filters={convertFilter(availableFilters[FilterKey.Profile])}
              onFilterChange={handleFilterChange(FilterKey.Profile)}
            />
            <CheckBoxGroup
              title={'Количество уровней в квартире'}
              filters={convertFilter(availableFilters[FilterKey.Levels])}
              onFilterChange={handleFilterChange(FilterKey.Levels)}
            />
            {selectedFilters[FilterKey.Project].length > 0 && (
              <CheckBoxGroup
                title={'Корпус'}
                filters={convertFilter(availableFilters[FilterKey.Building])}
                onFilterChange={handleFilterChange(FilterKey.Building)}
              />
            )}
            {selectedFilters[FilterKey.Project].length > 0 && (
              <CheckBoxGroup
                title={'Подъезд'}
                filters={convertFilter(availableFilters[FilterKey.Section])}
                onFilterChange={handleFilterChange(FilterKey.Section)}
              />
            )}
            {histograms[HistogramKey.Floor] && (
              <InputRange
                key="floor"
                title="Этаж"
                priceData={histograms[HistogramKey.Floor]!}
                onRangeChange={(min, max) => handleRangeChange(HistogramKey.Floor, min, max)}
              />
            )}
            {histograms[HistogramKey.Area] && (
              <InputRange
                key="area"
                title={`Площадь, ${LOT_CARDS_CONSTS.M2}`}
                priceData={histograms[HistogramKey.Area]!}
                onRangeChange={(min, max) => handleRangeChange(HistogramKey.Area, min, max)}
              />
            )}
            {histograms[HistogramKey.PriceRub] && (
              <InputRange
                key="price"
                title="Стоимость"
                minStep={1000000}
                priceData={histograms[HistogramKey.PriceRub]!}
                onRangeChange={(min, max) => handleRangeChange(HistogramKey.PriceRub, min, max)}
              />
            )}
            {histograms[HistogramKey.CeilingHeight] && (
              <InputRange
                key="ceiling"
                title="Высота потолка"
                minStep={0.1}
                priceData={histograms[HistogramKey.CeilingHeight]!}
                onRangeChange={(min, max) => handleRangeChange(HistogramKey.CeilingHeight, min, max)}
              />
            )}

            <CheckBoxGroup
              title={'Преимущества'}
              filters={convertFilter(availableFilters[FilterKey.Advantages])}
              onFilterChange={handleFilterChange(FilterKey.Advantages)}
            />
            <CheckBoxGroup
              title={'Тип кухни'}
              filters={convertFilter(availableFilters[FilterKey.KitchenType])}
              onFilterChange={handleFilterChange(FilterKey.KitchenType)}
            />
            <CheckBoxGroup
              title={'Планировка'}
              filters={convertFilter(availableFilters[FilterKey.Plan])}
              onFilterChange={handleFilterChange(FilterKey.Plan)}
            />
            <CheckBoxGroup
              title={'Ориентация по сторонам света'}
              filters={convertFilter(availableFilters[FilterKey.Orientation])}
              onFilterChange={handleFilterChange(FilterKey.Orientation)}
            />
          </div>
        </div>
        <RoompickerPage
          toggleFilterVisibility={toggleFilterVisibility}
          isFilterVisible={isFilterVisible}
          selectedFilters={selectedFilters}
          setSortKey={setSortOption}
        />
      </div>
    </>
  );
};
