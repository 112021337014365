import { FilterKey } from '@/app/api/common-types.ts';

export const PROJECT_SORTING_LOGIC: { [key: string]: string[] } = {
  '': [
    'Фрунзенская набережная',
    'Лаврушинский',
    'Обыденский № 1',
    'Ильинка 3/8',
    'Чистые Пруды',
    'Тишинский бульвар',
    'LIFE TIME',
    'West Garden',
    'Достижение',
    'Врубеля 4',
  ],
  'Фрунзенская набережная': [
    'Лаврушинский',
    'Обыденский № 1',
    'Ильинка 3/8',
    'Чистые Пруды',
    'Тишинский бульвар',
    'LIFE TIME',
    'West Garden',
    'Достижение',
    'Врубеля 4',
  ],
  Лаврушинский: [
    'Фрунзенская набережная',
    'Обыденский № 1',
    'Ильинка 3/8',
    'Чистые Пруды',
    'Тишинский бульвар',
    'LIFE TIME',
    'West Garden',
    'Достижение',
    'Врубеля 4',
  ],
  'Обыденский № 1': [
    'Ильинка 3/8',
    'Чистые Пруды',
    'Лаврушинский',
    'Фрунзенская набережная',
    'Тишинский бульвар',
    'LIFE TIME',
    'West Garden',
    'Достижение',
    'Врубеля 4',
  ],
  'Ильинка 3/8': [
    'Обыденский № 1',
    'Чистые Пруды',
    'Лаврушинский',
    'Фрунзенская набережная',
    'Тишинский бульвар',
    'LIFE TIME',
    'West Garden',
    'Достижение',
    'Врубеля 4',
  ],
  'Чистые Пруды': [
    'Ильинка 3/8',
    'Обыденский № 1',
    'Лаврушинский',
    'Фрунзенская набережная',
    'Тишинский бульвар',
    'LIFE TIME',
    'West Garden',
    'Достижение',
    'Врубеля 4',
  ],
  'Тишинский бульвар': [
    'LIFE TIME',
    'Лаврушинский',
    'Фрунзенская набережная',
    'West Garden',
    'Ильинка 3/8',
    'Обыденский № 1',
    'Чистые Пруды',
    'Достижение',
    'Врубеля 4',
  ],
  'LIFE TIME': [
    'Тишинский бульвар',
    'Достижение',
    'West Garden',
    'Лаврушинский',
    'Фрунзенская набережная',
    'Ильинка 3/8',
    'Обыденский № 1',
    'Чистые Пруды',
    'Врубеля 4',
  ],
  'West Garden': [
    'LIFE TIME',
    'Достижение',
    'Тишинский бульвар',
    'Лаврушинский',
    'Фрунзенская набережная',
    'Обыденский № 1',
    'Чистые Пруды',
    'Ильинка 3/8',
    'Врубеля 4',
  ],
  Достижение: [
    'West Garden',
    'LIFE TIME',
    'Тишинский бульвар',
    'Лаврушинский',
    'Фрунзенская набережная',
    'Чистые Пруды',
    'Ильинка 3/8',
    'Обыденский № 1',
    'Врубеля 4',
  ],
};
export const TISHINSKIY_ID = 'Тишинский бульвар';
export const ILYINKA_ID = 'Ильинка 3/8';

const PROFILE_MAP = {
  Жилая: [
    'Ситихаус',
    'Квартира',
    'Пентхаус',
    'Особняк',
    'Скай-вилла',
    'Вилла',
    'Апартаменты',
    'Усадьба',
    'Дуплекс',
    'Хайлевел',
    'Флэтхаус',
    'Таунхаус',
  ],
  Коммерческая: [
    'Автомойка',
    'Ритейл',
    'Вилла (к)',
    'Детский сад',
    'Особняк (к)',
    'Машиноместо (к)',
    'Машиноместо (Ритейл)',
    'Склад (Ритейл)',
  ],
  Офисы: ['Офис', 'Машиноместо (Офис)', 'Склад (Офис)'],
  Машиноместа: ['Вело/мотоместо', 'Машиноместо (ж)'],
  Келлеры: ['Склад (ж)'],
};

export function filterProjects(projects: string[]): string[] {
  return projects.filter((project) => PROJECT_SORTING_LOGIC[project]);
}

export function mapProfilesToType(profile: string): string | undefined {
  for (const [key, profiles] of Object.entries(PROFILE_MAP)) {
    if (profiles.includes(profile)) {
      return key;
    }
  }
  return undefined;
}

export function getTypeProfiles(type: string): string[] {
  for (const [key, profiles] of Object.entries(PROFILE_MAP)) {
    if (key === type) {
      return profiles;
    }
  }
  return [];
}

export function getAllProfiles(): string[] {
  const allProfiles: string[] = [];
  for (const [, profiles] of Object.entries(PROFILE_MAP)) {
    allProfiles.push(...profiles);
  }
  return collectValues(allProfiles);
}

export function sortLotTypes(values: string[]): string[] {
  const result: string[] = [];
  Object.keys(PROFILE_MAP).forEach((v) => {
    if (values.includes(v)) {
      result.push(v);
    }
  });
  return result;
}

const BEDROOMS_VALUES = ['1', '2', '3', '4', '5', '5+'];

export function sortBedrooms(values: string[]): string[] {
  const result: string[] = [];
  BEDROOMS_VALUES.forEach((v) => {
    if (values.includes(v)) {
      result.push(v);
    }
  });
  return result;
}

const SIZES_VALUES = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];

export function sortSizes(values: string[]): string[] {
  const result: string[] = [];
  SIZES_VALUES.forEach((v) => {
    if (values.includes(v)) {
      result.push(v);
    }
  });
  return result;
}

const LEVELS_VALUES = ['Одноуровневые', 'Двухуровненвые', 'Трёхуровневые'];

export function sortLevels(values: string[]): string[] {
  const result: string[] = [];
  LEVELS_VALUES.forEach((v, i) => {
    if (values.includes((i + 1).toString())) {
      result.push(v);
    }
  });
  return result;
}

export function mapLevels(values: string[]): string[] {
  return values.map((v) => {
    return (LEVELS_VALUES.indexOf(v) + 1).toString();
  });
}

export const ADVANTAGES_FIELDS = [
  'МоллированныеОкна',
  'Балкон',
  'Терраса',
  'Лоджия',
  'Эркер',
  'Патио',
  'Камин',
  'ВидоваяКвартира',
  'Бассейн',
  'ОтдельныйВход',
  'МокраяТочкаВОфисе',
  'ОтдельнаяВентиляция',
  'ОхраняемаяТерритория',
  'ПогрузкаРазгрузка',
  'ПерваяЛинияДомов',
  'ВозможностьВывески',
  'Витрины',
  'ТеррасаРитейл',
  'Электрозарядка',
  'МашиноместоСКеллером',
  'УвеличеннаяПлощадь',
];

export function mapAdvantage(value: string): string {
  return value.split(/(?=[А-Я])/).join(' ');
}

interface ISet {
  [key: string]: undefined;
}

export function collectValues(valuesCombinations: string[]): string[] {
  const set: ISet = {};
  valuesCombinations.forEach((comb) => {
    comb
      .split(/[,;]/)
      .map((s) => s.trim())
      .forEach((value) => {
        if (value.trim().length > 0) {
          set[value] = undefined;
        }
      });
  });
  return Object.keys(set);
}

export function updateFilterStats(key: FilterKey, prevValue: string[], newValue: string[]) {
  switch (key) {
    //case FilterKey.Project:
    case FilterKey.Type:
      return prevValue.length === 0 ? newValue : prevValue;
    default:
      return newValue;
  }
}
