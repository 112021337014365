import { IHistogramData } from '@/features/input-range/model/types.ts';
import { ISortOption } from '@/shared/model/types.ts';

export enum FilterKey {
  Project,
  Type,
  Bedrooms,
  Sizes,
  Levels,
  HideBooked,
  ProjectIsComplete,
  FlatNumber,
  Profile,
  Building,
  Section,
  Advantages,
  KitchenType,
  Plan,
  Orientation,
}

export enum HistogramKey {
  Floor,
  Area,
  PriceRub,
  PriceUsd,
  PriceEur,
  PriceCny,
  CeilingHeight,
}

export enum SortKey {
  PriceDesc,
  PriceAsc,
  AreaDesc,
  AreaAsc,
  BedroomsDesc,
  BedroomsAsc,
  BedroomSizeDesc,
  BedroomSizeAsc,
}

export type RoompickerFiltersValues = {
  [key in FilterKey]: string[];
};
export type RoompickerHistogramValues = {
  [key in HistogramKey]: IHistogramData | undefined;
};

export function emptyFilters(): RoompickerFiltersValues {
  return {
    [FilterKey.Project]: [],
    [FilterKey.Type]: [],
    [FilterKey.Bedrooms]: [],
    [FilterKey.Sizes]: [],
    [FilterKey.Levels]: [],
    [FilterKey.HideBooked]: [],
    [FilterKey.ProjectIsComplete]: [],
    [FilterKey.FlatNumber]: [],
    [FilterKey.Profile]: [],
    [FilterKey.Building]: [],
    [FilterKey.Section]: [],
    [FilterKey.Advantages]: [],
    [FilterKey.KitchenType]: [],
    [FilterKey.Plan]: [],
    [FilterKey.Orientation]: [],
  };
}

export function emptyHistograms(): RoompickerHistogramValues {
  return {
    [HistogramKey.PriceRub]: undefined,
    [HistogramKey.PriceUsd]: undefined,
    [HistogramKey.PriceEur]: undefined,
    [HistogramKey.PriceCny]: undefined,
    [HistogramKey.Floor]: undefined,
    [HistogramKey.Area]: undefined,
    [HistogramKey.CeilingHeight]: undefined,
  };
}

export function getSortKey(option: ISortOption): SortKey {
  for (const k of Object.values(SortKey)) {
    const key = k as SortKey;
    if (key && key.toString() === option.id) {
      console.log(`found key ${key}`);
      return key;
    }
  }
  return SortKey.PriceDesc; // default sort option
}
