import { useMemo, useState } from 'react';
import { Dropdown } from '@/shared/ui/components/dropdown';
import { RoompickerHeader } from '@/features/roompicker-header';
import { RoompickerGridCard } from '@/features/roompicker-grid-card';
import { DEFAULT_DROPDOWN_SORTING_OPTIONS, IApartment } from '@/pages/roompicker-page/model/types.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/roompicker-page/ui/styles.ts';

import { HousingSelectorGrid } from '@/features/data-grid';
import { columns, columnsShort } from '@/features/data-grid/ui/header/ui/mock-columns';
import { LotCards } from '@/features/lot-cards';
import useIsMobile from '@/shared/hooks/useIsMobile';
import { useOutletContext } from 'react-router-dom';
import { SelectionContextType } from '@/pages/selection/model/types';
import { countSelectedFilters } from '../lib/filterBadgeCount';
import { removeField } from '../lib/removeProject';
import { FilterKey, getSortKey, RoompickerFiltersValues, SortKey } from '@/app/api/common-types.ts';

interface RoompickerPageProps {
  toggleFilterVisibility: () => void;
  isFilterVisible: boolean;
  selectedFilters: RoompickerFiltersValues;
  setSortKey: (key: SortKey) => void;
}

const RoompickerPage = ({
  toggleFilterVisibility,
  isFilterVisible,
  selectedFilters,
  setSortKey,
}: RoompickerPageProps) => {
  const [isGridRow, setIsGridRow] = useState<boolean>(true);
  //const [sort, setSort] = useState<ISortOption | null>(DEFAULT_DROPDOWN_SORTING_OPTIONS?.[3]);
  const { apartsToShow, sortOption } = useOutletContext<SelectionContextType>();
  const { isMobile } = useIsMobile();

  const isSingleProjectSelected = useMemo(
    () => selectedFilters[FilterKey.Project].length === 1,
    [selectedFilters[FilterKey.Project]]
  );

  const filterBadgeCount = useMemo(() => countSelectedFilters(selectedFilters), [selectedFilters]);

  const toCompareCount = useMemo(
    () => apartsToShow.filter((apartment) => apartment.isToCompare).length,
    [apartsToShow]
  );

  // Обработчик для добавления/удаления из сравнения
  const compareOnClickHandler = (apartment: IApartment) => {
    /*setApartments((prev) => {
      const updated = prev.map((item) =>
        item.id === apartment.id ? { ...item, isToCompare: !item.isToCompare } : item
      );
      return updated;
    });*/
  };

  // Обработчик для добавления/удаления из избранного
  const favoriteOnClickHandler = (apartment: IApartment) => {
    /*setApartments((prev) => {
      const updated = prev.map((item) => (item.id === apartment.id ? { ...item, isFavorite: !item.isFavorite } : item));
      return updated;
    });*/
  };

  return (
    <div {...stylex.props(styles.moreContainer(isFilterVisible))}>
      <div {...stylex.props(styles.container)}>
        {!isFilterVisible && (
          <div {...stylex.props(styles.headerWrapper)}>
            <RoompickerHeader
              isGridRow={isGridRow}
              compareBadgeCount={toCompareCount}
              filterBadgeCount={filterBadgeCount}
              gridOnClick={() => setIsGridRow(true)}
              rowOnClick={() => setIsGridRow(false)}
              dropdownOnSelect={(option) => {
                setSortKey(getSortKey(option));
              }}
              compareOnClick={toggleFilterVisibility}
              sortingOptions={DEFAULT_DROPDOWN_SORTING_OPTIONS}
              defaultSortingOptionId={sortOption.toString()}
            />
          </div>
        )}
        <div {...stylex.props(styles.cardsContainer(isFilterVisible, isGridRow))}>
          {/* На мобильной версии выпадающее меню является частью страницы, а не хедера */}
          <div {...stylex.props(styles.desktopHide(isFilterVisible))}>
            <Dropdown
              options={DEFAULT_DROPDOWN_SORTING_OPTIONS}
              onSelect={(option) => {
                setSortKey(getSortKey(option));
              }}
              defaultOptionId={sortOption.toString()}
            />
          </div>
          {isGridRow ? (
            apartsToShow.map((apartment, index) => (
              <RoompickerGridCard
                key={index}
                infoProps={
                  isSingleProjectSelected
                    ? (removeField(apartment as IApartment, 'buildingName') as IApartment)
                    : apartment
                }
                isToComparison={apartment.isToCompare}
                isFavorite={apartment.isFavorite}
                onCompareClick={() => compareOnClickHandler(apartment)}
                onFavoriteClick={() => favoriteOnClickHandler(apartment)}
              />
            ))
          ) : !isMobile ? (
            <HousingSelectorGrid
              columns={isSingleProjectSelected ? columnsShort : columns}
              rows={
                isSingleProjectSelected
                  ? (removeField(apartsToShow as IApartment[], 'buildingName') as IApartment[])
                  : apartsToShow
              }
              compareOnClickHandler={compareOnClickHandler}
            />
          ) : (
            <>
              <LotCards rows={apartsToShow} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoompickerPage;
