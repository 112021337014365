import { RoompickerFiltersValues } from '@/app/api/graphql-stats.ts';

/**
 * Подсчитывает общее количество выбранных значений во всех фильтрах.
 * @param selectedFilters Объект, где ключи — категории фильтров, а значения — массивы выбранных значений.
 * @returns Общее количество выбранных значений.
 */
export const countSelectedFilters = (selectedFilters: RoompickerFiltersValues): number => {
    return Object.values(selectedFilters).reduce((acc, filterArray) => acc + filterArray.length, 0);
  };
  