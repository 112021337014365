import * as stylex from '@stylexjs/stylex';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';
import { palette } from '@/shared/tokens/palette.stylex.ts';

export const styles = stylex.create({
  input: {
    display: 'none',
  },
  checkboxGroupContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  checkboxGroupTitle: {
    fontFamily: fonts.din2014Regular,
    color: palette['grey-700'],
    fontSize: '12px',
    lineHeight: '12px',
    paddingBottom: '8px',
  },
  container: {},
});
