import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { renderFullCost, renderImg, renderPriceForSquare, renderSquare, renderTableActions } from '../../render-props';
import { useState } from 'react';
import { IApartment } from '@/pages/roompicker-page/model/types';

export interface IRowProps {
  row: IApartment;
  gridTemplateColumns: string;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setSelectedApartment: (selectedApartment: IApartment | null) => void;
  compareOnClickHandler?: (apartment: IApartment) => void;
}

export const Row = ({
  row,
  gridTemplateColumns,
  setIsModalOpen,
  setSelectedApartment,
  compareOnClickHandler,
}: IRowProps) => {
  const [isAddedToFavs, setIsAddedToFavs] = useState<boolean>(row.isFavorite!);
  const [isAddedToCompare, setIsAddedToCompare] = useState<boolean>(row.isToCompare!);

  const clickHandler = (e: React.MouseEvent, row: IApartment) => {
    e.stopPropagation();
    setIsModalOpen(true);
    setSelectedApartment(row);
  };
  const addToFavsHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsAddedToFavs(!isAddedToFavs);
  };

  const addToCompareHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsAddedToCompare(!isAddedToCompare);
    compareOnClickHandler && compareOnClickHandler(row);
  };
  return (
    <div
      key={row.id}
      {...stylex.props(styles.tableRow)}
      role="button"
      onClick={() => {}}
      style={{ gridTemplateColumns }}
    >
      {row.projectName && <div {...stylex.props(styles.tableCell)}>{row.projectName}</div>}
      <div {...stylex.props(styles.tableCell, styles.tableCellSchema)}>
        {renderImg(row, styles.tableCellImg, (e) => clickHandler(e, row))}
      </div>
      <div {...stylex.props(styles.tableCell)}>{row.bedroomCount}</div>
      <div {...stylex.props(styles.tableCell)}>{renderSquare(row, styles.tableCellUnitMeasure)}</div>
      <div {...stylex.props(styles.tableCell)}>{row.size}</div>
      <div {...stylex.props(styles.tableCell)}>{row.type}</div>
      <div {...stylex.props(styles.tableCell)}>{row.floor}</div>
      <div {...stylex.props(styles.tableCell)}>{row.building ? row.building : '-'}</div>
      <div {...stylex.props(styles.tableCell)}>{row.number}</div>
      <div {...stylex.props(styles.tableCell)}>{renderPriceForSquare(row, styles.tableCellUnitMeasure)}</div>
      <div {...stylex.props(styles.tableCell)}>{renderFullCost(row, styles.tableCellUnitMeasure)}</div>
      <div {...stylex.props(styles.tableCell, styles.tableCellIcons(row.project))}>
        {renderTableActions(styles.icon, addToFavsHandler, addToCompareHandler, isAddedToFavs, isAddedToCompare)}
      </div>
    </div>
  );
};
