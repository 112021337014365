import { UpperSection } from '@/features/roompicker-grid-card/ui/upper-section.tsx';
import { ImageSection } from '@/features/roompicker-grid-card/ui/image-section.tsx';
import { LowerSection } from '@/features/roompicker-grid-card/ui/lower-section.tsx';
import { IRoompickerGridCardProps } from '@/features/roompicker-grid-card/model/types.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from './stylex.ts';
import { Modal } from '@/shared/ui/modal';
import { ApartmentPlan } from '@/features/apartment-plan/index.ts';
import { IApartment } from '@/pages/roompicker-page/model/types.ts';
import { useState } from 'react';
import { CURRENCY_ENUM } from '@/shared/model/roompicker.ts';

const RoompickerGridCard = ({
  infoProps,
  onCompareClick,
  onFavoriteClick,
  onMainClick,
  isFavorite,
  isToComparison,
}: IRoompickerGridCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApartment, setSelectedApartment] = useState<IApartment | null>(null);
  const {area, address, valueRub, size, priceRub, bedroomCount, projectName, imgLink} =
    infoProps;

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedApartment(null);
  };
  const modalClick = () => {
    setIsModalOpen(true);
  };
  return (
    <div {...stylex.props(styles.container)}>
      <UpperSection
        address={address}
        buildingName={projectName}
        showBuildingName={true}
        isFavorite={isFavorite}
        isToComparison={isToComparison}
        onCompareClick={onCompareClick}
        onFavoriteClick={onFavoriteClick}
      />
      <ImageSection
        infoProps={infoProps}
        address={address}
        onClick={modalClick}
        imgLink={imgLink}
        setSelectedApartment={setSelectedApartment}
      />
      <LowerSection
        size={size}
        area={area}
        value={valueRub}
        price={priceRub}
        currencyCode={CURRENCY_ENUM.RUB}
        bedroomCount={bedroomCount}
        onMainClick={onMainClick}
      />
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} isFullScreen isModalForApartmentPlan>
        <ApartmentPlan apartment={selectedApartment} />
      </Modal>
    </div>
  );
};

export default RoompickerGridCard;
