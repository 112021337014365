import { IApartment } from '@/pages/roompicker-page/model/types';
import { collectValues, sortBedrooms, sortSizes } from './graphql-common';
import { IGQLLotOutput, IGQLPickerOutput } from './roompicker-graphql';

interface PickData {
  data: PickResponse | undefined;
  error: any;
}

interface PickResponse {
  pick: IGQLPickerOutput;
}

export function parsePick(jsonStr: string): IApartment[] {
  const json: PickData = JSON.parse(jsonStr);
  if (json.data) {
    return json.data.pick.connection.nodes.map((lot) => convertLot(lot));
  }
  return [];
}

function convertLot(lot: IGQLLotOutput): IApartment {
  let projectName = '';
  lot.project.attributes.forEach((attr) => {
    if (attr.name === 'Наименование') {
      projectName = attr.value;
    }
  });
  let building = '';
  lot.building.attributes.forEach((attr) => {
    if (attr.name === 'Наименование') {
      building = transformBuilding(attr.value);
    }
  });
  const id = lot.flat.flatId;
  let prices = [0, 0, 0, 0];
  let values = [0, 0, 0, 0];
  let type = '';
  let size = '';
  let area = 0.0;
  let bedroomCount = '';
  let number = '';
  let floor = '';
  lot.flat.attributes.forEach((attr) => {
    switch (attr.name) {
      case 'ЦенаЭкспонированияСоСкидкой':
        prices[0] = parseFloat(attr.value);
        break;
      case 'ЦенаЭкспонированияСоСкидкойUSD':
        prices[1] = parseFloat(attr.value);
        break;
      case 'ЦенаЭкспонированияСоСкидкойEUR':
        prices[2] = parseFloat(attr.value);
        break;
      case 'ЦенаЭкспонированияСоСкидкойCNY':
        prices[3] = parseFloat(attr.value);
        break;
      case 'ЦенаЭкспонированияСоСкидкойКвМ':
        values[0] = parseFloat(attr.value);
        break;
      case 'ЦенаЭкспонированияСоСкидкойКвМUSD':
        values[1] = parseFloat(attr.value);
        break;
      case 'ЦенаЭкспонированияСоСкидкойКвМEUR':
        values[2] = parseFloat(attr.value);
        break;
      case 'ЦенаЭкспонированияСоСкидкойКвМCNY':
        values[3] = parseFloat(attr.value);
        break;
      case 'Профиль':
        type = attr.value;
        break;
      case 'Типоразмер':
        size = sortSizes(collectValues([attr.value])).join('/');
        break;
      case 'Площадь':
        area = parseFloat(attr.value);
        break;
      case 'КоличествоКомнат':
        bedroomCount = sortBedrooms(collectValues([attr.value])).join('/');
        break;
      case 'Наименование':
        number = attr.value;
        break;
      case 'ОсновнойЭтаж':
        floor = attr.value;
        break;
    }
  });
  let schemaSrcFurniture: string | undefined = undefined;
  let schemaSrcSizes: string | undefined = undefined;
  lot.flat.planes.forEach((plane) => {
    if (
      plane.planOrder === '1' &&
      plane.link.toLowerCase().endsWith('.png')
    ) {
      if (plane.planType.toUpperCase().includes('МЕБЕЛ')) {
        schemaSrcFurniture = plane.link;
      } else if (plane.planType.toUpperCase().includes('РАЗМЕР')) {
        schemaSrcSizes = plane.link;
      }
    }
  });
  const imgLink = schemaSrcFurniture ?? schemaSrcSizes ?? '';

  return {
    id,
    projectName,
    address: 'Корпус: ' + building + ', Этаж: ' + floor + ', № ' + number,
    priceRub: prices[0],
    valueRub: values[0],
    priceUsd: prices[1],
    valueUsd: values[1],
    priceEur: prices[2],
    valueEur: values[2],
    priceCny: prices[3],
    valueCny: values[3],
    area,
    size,
    type,
    floor,
    bedroomCount,
    number,
    building,
    imgLink,
    schemaSrcFurniture,
    schemaSrcSizes,
  };
}

function transformBuilding(building: string): string {
  const noHousing = building.replace(/корпус/i, '').trim();
  if (noHousing.match(/[1-9]/)) {
    return noHousing;
  }
  return noHousing.charAt(0).toUpperCase() + noHousing.slice(1).toLowerCase();
}
