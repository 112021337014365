import React, { InputHTMLAttributes } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  errorMessage?: string;
  onClick?: () => void;
}

export const Input: React.FC<InputProps> = ({ onClick, label, leftIcon, rightIcon, errorMessage, ...props }) => {
  return (
    <div {...stylex.props(styles.inputContainer)}>
      {label && <label {...stylex.props(styles.label)}>{label}</label>}

      <div {...stylex.props(styles.inputWrapper)}>
        {leftIcon && <span {...stylex.props(styles.iconLeft)}>{leftIcon}</span>}

        <input
          {...stylex.props([
            styles.inputField,
            leftIcon ? styles.withLeftIcon : null,
            rightIcon ? styles.withRightIcon : null,
          ])}
          {...props}
        />

        {rightIcon && (
          <span {...stylex.props(styles.iconRight)} onClick={onClick}>
            {rightIcon}
          </span>
        )}
      </div>

      {errorMessage && (
        <span {...stylex.props(styles.error)} onClick={onClick}>
          {errorMessage}
        </span>
      )}
    </div>
  );
};
