import { ReactNode } from 'react';
import { ISortOption } from '@/shared/model/types.ts';
import { SortKey } from '@/app/api/common-types.ts';

export interface IApartment {
  [x: string]: ReactNode;
  id: string;
  projectName?: string;
  address: string;
  priceRub: number;
  valueRub: number;
  priceUsd: number;
  valueUsd: number;
  priceEur: number;
  valueEur: number;
  priceCny: number;
  valueCny: number;
  area: number;
  size: string;
  type: string;
  floor: string;
  bedroomCount: string;
  number: string;
  imgLink: string;
  schemaSrcFurniture?: string;
  schemaSrcSizes?: string;
  building?: string;

  isToCompare?: boolean;
  isFavorite?: boolean;
}

export const DEFAULT_DROPDOWN_SORTING_OPTIONS: ISortOption[] = [
  {
    title: 'Стоимость сначала дороже',
    id: SortKey.PriceDesc.toString(),
  },
  {
    title: 'Стоимость сначала дешевле',
    id: SortKey.PriceAsc.toString(),
  },
  {
    title: 'Площадь сначала большая',
    id: SortKey.AreaDesc.toString(),
  },
  {
    title: 'Площадь сначала меньшая',
    id: SortKey.AreaAsc.toString(),
  },
  {
    title: 'Спален сначала больше',
    id: SortKey.BedroomsDesc.toString(),
  },
  {
    title: 'Спален сначала меньше',
    id: SortKey.BedroomsAsc.toString(),
  },
  {
    title: 'Спальни сначала большие',
    id: SortKey.BedroomSizeDesc.toString(),
  },
  {
    title: 'Спальни сначала маленькие',
    id: SortKey.BedroomSizeAsc.toString(),
  },
];
