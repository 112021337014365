import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  headerGrid: {
    display: 'grid',
    width: '100%',
    backgroundColor: colors.brandGhostBackground,
  },
  tableHeader: (sortable) => ({
    fontSize: '12px',
    lineHeight: '12px',
    fontStyle: 'normal',
    color: colors.tertiaryForeground,
    fontWeight: 400,
    textAlign: 'left',
    gap: '8px',
    padding: '8px',
    cursor: sortable ? 'pointer' : 'default',
    // '@media (min-width: 1024px)': {
    //   fontSize: '1.17rem',
    //   lineHeight: '1.17rem',
    //   gap: '0.78rem',
    //   padding: '0.78rem 1.95rem 0.78rem 1.17rem',
    // },
  }),

  tableHeaderWithIcons: {
    fontSize: '12px',
    lineHeight: '12px',
    fontStyle: 'normal',
    color: colors.tertiaryForeground,
    fontWeight: 400,
    textAlign: 'left',
    gap: '4px',
    padding: '8px',
    display: 'flex',
    alignItems: 'flex-start',
    ':hover': {
      cursor: 'pointer',
    },
    // '@media (min-width: 1024px)': {
    //   fontSize: '1.17rem',
    //   lineHeight: '1.17rem',
    //   gap: '0.2rem',
    //   padding: '0.78rem 1.17rem',
    // },
  },
  headerText: {
    padding: '4px 0px',
    fontSize: '0.834rem',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },

  icon: {
    width: '.833rem',
    height: '.833rem',
    paddingBottom: '4px',
    paddingTop: '4px',
    ':not(#__unused__) > svg': {
      width: '.833rem',
      height: '.833rem',
    },

    // '@media (min-width: 1024px)': {
    //   width: '1.17rem',
    //   height: '1.17rem',
    // },
  },
});
