import {
  ADVANTAGES_FIELDS,
  collectValues,
  filterProjects,
  mapAdvantage,
  mapProfilesToType,
  sortBedrooms,
  sortLevels,
  sortLotTypes,
  sortSizes,
} from './graphql-common';
import { IGQLAttributeStatsOutput, IGQLHistogramOutput, IGQLStatsResponse } from './roompicker-graphql';
import { IHistogramData } from '@/features/input-range/model/types.ts';
import {
  emptyFilters,
  emptyHistograms,
  FilterKey,
  HistogramKey,
  RoompickerFiltersValues,
  RoompickerHistogramValues,
} from '@/app/api/common-types.ts';

interface StatsData {
  data: StatsResponse | undefined;
  error: never;
}

interface StatsResponse {
  stats: IGQLStatsResponse;
}

interface IFilterWithKey {
  key: FilterKey;
  items: string[];
}

interface IHistogramWithKey {
  key: HistogramKey;
  data: IHistogramData;
}

export function parseStats(jsonStr: string): [RoompickerFiltersValues, RoompickerHistogramValues] {
  const filtersValues = emptyFilters();
  const histogramValues = emptyHistograms();
  const json: StatsData = JSON.parse(jsonStr);
  if (json.data) {
    json.data.stats.fields.forEach((output) => {
      const filters = processFieldStats(output);
      if (filters) {
        filters.forEach((filter) => {
          filtersValues[filter.key] = [...filtersValues[filter.key], ...filter.items];
        });
      }
      const histogram = processHistogram(output);
      if (histogram) {
        histogramValues[histogram.key] = histogram.data;
      }
    });
  }
  return [filtersValues, histogramValues];
}

function processFieldStats(stats: IGQLAttributeStatsOutput): IFilterWithKey[] | undefined {
  switch (stats.entity) {
    case 'project':
      if (stats.field === 'Наименование') {
        return [valuesToFilterItems(FilterKey.Project, filterProjects(stats.values))];
      }
      break;
    case 'building':
      if (stats.field === 'Наименование') {
        return [valuesToFilterItems(FilterKey.Building, stats.values)];
      }
      break;
    case 'flat':
      if (ADVANTAGES_FIELDS.includes(stats.field)) {
        return stats.values.includes('true')
          ? [valuesToFilterItems(FilterKey.Advantages, [mapAdvantage(stats.field)])]
          : undefined;
      }
      switch (stats.field) {
        case 'Профиль': {
          const types = collectValues(stats.values)
            .map((v) => mapProfilesToType(v))
            .filter((v) => v)
            .map((v) => v!);
          const profiles = collectValues(stats.values)
            .filter((v) => v)
            .map((v) => v!);
          return [
            valuesToFilterItems(FilterKey.Type, sortLotTypes(types)),
            valuesToFilterItems(FilterKey.Profile, profiles),
          ];
        }
        case 'КоличествоКомнат':
          return [valuesToFilterItems(FilterKey.Bedrooms, sortBedrooms(collectValues(stats.values)))];
        case 'Типоразмер':
          return [valuesToFilterItems(FilterKey.Sizes, sortSizes(collectValues(stats.values)))];
        case 'КоличествоУровней':
          return [valuesToFilterItems(FilterKey.Levels, sortLevels(collectValues(stats.values)))];
        case 'НомерСекции':
          return [valuesToFilterItems(FilterKey.Section, collectValues(stats.values).sort())];
        case 'ТипКухни':
          return [valuesToFilterItems(FilterKey.KitchenType, collectValues(stats.values).sort())];
        case 'ВыходОкон':
          return [valuesToFilterItems(FilterKey.Plan, collectValues(stats.values).sort())];
        case 'СтороныСвета':
          return [valuesToFilterItems(FilterKey.Orientation, collectValues(stats.values).sort())];
      }
      break;
  }
}

function processHistogram(stats: IGQLAttributeStatsOutput): IHistogramWithKey | undefined {
  if (stats.histogram) {
    switch (stats.field) {
      case 'ОсновнойЭтаж':
        return convertHistogram(HistogramKey.Floor, stats.histogram);
      case 'Площадь':
        return convertHistogram(HistogramKey.Area, stats.histogram);
      case 'ЦенаЭкспонированияСоСкидкой':
        return convertHistogram(HistogramKey.PriceRub, stats.histogram);
      case 'ЦенаЭкспонированияСоСкидкойUSD':
        return convertHistogram(HistogramKey.PriceUsd, stats.histogram);
      case 'ЦенаЭкспонированияСоСкидкойEUR':
        return convertHistogram(HistogramKey.PriceEur, stats.histogram);
      case 'ЦенаЭкспонированияСоСкидкойCNY':
        return convertHistogram(HistogramKey.PriceCny, stats.histogram);
      case 'ВысотаПотолка':
        return convertHistogram(HistogramKey.CeilingHeight, stats.histogram);
    }
  }
}

function convertHistogram(key: HistogramKey, histogram: IGQLHistogramOutput): IHistogramWithKey {
  return {
    key,
    data: {
      min: histogram.min,
      max: histogram.max,
      histogram: histogram.data,
    },
  };
}

function valuesToFilterItems(key: FilterKey, items: string[]): IFilterWithKey {
  return {
    key,
    items,
  };
}
