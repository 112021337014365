import stylex from '@stylexjs/stylex';
//import { LOT_CARDS_CONSTS } from '@/shared/lib/consts';
import { styles } from './styles';
import { IApartment } from '@/pages/roompicker-page/model/types';
import { getWordEnding } from '@/shared/utils/roompicker';
import { BEDROOM_WORD_ENDINGS } from '@/shared/constants/words';
export interface IMiddleBlockProps {
  row: IApartment;
}
export const MiddleBlock = ({ row }: IMiddleBlockProps) => {
  console.log(row);

  return (
    <div {...stylex.props(styles.middleBlock)}>
      <span {...stylex.props(styles.middleBlockText)}>{`${row.type} ${row.size}`}</span>
      <span {...stylex.props(styles.middleBlockText, styles.tertiaryForegroundColor)}>•</span>
      <span {...stylex.props(styles.middleBlockText)}>{`${row.area} ${row.measure}`}</span>
      <span {...stylex.props(styles.middleBlockText, styles.tertiaryForegroundColor)}>•</span>
      <span
        {...stylex.props(styles.middleBlockText)}
      >{`${row.bedroomCount} ${getWordEnding(parseInt(row.bedroomCount), BEDROOM_WORD_ENDINGS)}`}</span>
    </div>
  );
};
